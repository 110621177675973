import { Component, QueryList, ViewChildren } from '@angular/core';
import { ApiService } from './shared';
import { DropdownDirective } from './shared/dropdown';

@Component({
    selector: 'my-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    signInError = false;
    @ViewChildren(DropdownDirective) dropdowns: QueryList<DropdownDirective>;

    constructor(
        private apiService: ApiService
    ) { }

    signIn(email, password) {
        this.signInError = false;
        this.apiService.signin(email, password)
        .subscribe(result => {
            if (result) {
                this.closeDropDown();
            } else {
                this.signInError = true;
            }
        },
        () => {
            this.signInError = true;
        });
    }

    signOut() {
        this.apiService.signout();
        this.closeDropDown();
    }

    get user(): any {
        return this.apiService.user;
    }

    closeDropDown() {
        this.dropdowns.forEach(item => {
            item.close();
        });
    }
}
