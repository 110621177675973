import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownNotClosableDirective } from './dropdown.notclosable.directive';
import { DropdownDirective } from './dropdown.directive';
import { DropdownOpenDirective } from './dropdown.open.directive';

export * from './dropdown.notclosable.directive';
export * from './dropdown.directive';
export * from './dropdown.open.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        DropdownNotClosableDirective,
        DropdownDirective,
        DropdownOpenDirective,
    ],
    exports: [
        DropdownNotClosableDirective,
        DropdownDirective,
        DropdownOpenDirective,
    ]
})

export class DropdownModule {

}
