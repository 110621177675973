import { Component, OnInit } from '@angular/core';
import { ApiService } from '../shared';
import { ModalService } from '../shared/modal/modal.module';
import { JsonDetailModalComponent } from '../shared/modal/json-detail-modal.component';

@Component({
    selector: 'my-stats',
    templateUrl: './stats.component.html',
    styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {
    stats_db: any = {};
    stats_clients: any = {};
    stats_clientlogs: any = {};
    stats_jobs: any = {};
    stats_joblogs: any = {};
    stats_links: any = {};
    stats_notifications: any = {};
    stats_subscriptions: any = {};
    stats_subscriptionlogs: any = {};
    stats_users: any = {};
    stats_requests: any = {};

    constructor(
        private apiService: ApiService,
        private modalService: ModalService
    ) { }

    ngOnInit() {
        this.apiService.statsGetDb().subscribe(result => this.stats_db = result);
        this.apiService.statsGetDbCol('clients').subscribe(result => this.stats_clients = result);
        this.apiService.statsGetDbCol('clientlogs').subscribe(result => this.stats_clientlogs = result);
        this.apiService.statsGetDbCol('jobs').subscribe(result => this.stats_jobs = result);
        this.apiService.statsGetDbCol('joblogs').subscribe(result => this.stats_joblogs = result);
        this.apiService.statsGetDbCol('links').subscribe(result => this.stats_links = result);
        this.apiService.statsGetDbCol('notifications').subscribe(result => this.stats_notifications = result);
        this.apiService.statsGetDbCol('subscriptions').subscribe(result => this.stats_subscriptions = result);
        this.apiService.statsGetDbCol('subscriptionlogs').subscribe(result => this.stats_subscriptionlogs = result);
        this.apiService.statsGetDbCol('users').subscribe(result => this.stats_users = result);
        this.apiService.statsGetDbCol('requests').subscribe(result => this.stats_requests = result);
    }

    openStatsDetail(title: string, colname: string) {
        const modal = this.modalService.create(JsonDetailModalComponent, {
            title: title,
            item: this['stats_' + colname]
        });
    }

}
