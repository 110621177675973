import {Component, NgModule, ViewChild, OnInit, ViewContainerRef,
    ReflectiveInjector, Injectable, Injector, Type,
    ComponentRef, ComponentFactoryResolver} from '@angular/core';
import {Observable, Subject, ReplaySubject} from 'rxjs';

// the modalservice
@Injectable()
export class ModalService {
    private vcRef: ViewContainerRef;
    public activeInstances: number;
    activeInstances$: Subject<number> = new Subject();
    private resolver: ComponentFactoryResolver;
    modalRef: ComponentRef<any>[] = [];

    constructor() {
    }

    registerViewContainerRef(vcRef: ViewContainerRef): void {
        this.vcRef = vcRef;
    }

    public RegisterResolver(resolver: ComponentFactoryResolver) {
        this.resolver = resolver;
    }

    create<T>(component: Type<T>, parameters?: Object): Observable<ComponentRef<T>> {
        const componentRef$ = new ReplaySubject();

        const injector = ReflectiveInjector.fromResolvedProviders([], this.vcRef.parentInjector),
        factory = this.resolver.resolveComponentFactory(component),
        componentRef = factory.create(injector);

        this.vcRef.insert(componentRef.hostView);
        Object.assign(componentRef.instance, parameters); // pass the @Input parameters to the instance
        this.activeInstances++;

        this.activeInstances$.next(this.activeInstances);

        componentRef.instance['componentIndex'] = this.activeInstances;
        componentRef.instance['destroy'] = () => {
            this.activeInstances--;
            this.activeInstances = Math.max(this.activeInstances, 0);
            // remove modal instance from active instances array
            const idx = this.modalRef.indexOf(componentRef);
            if (idx > -1) {
                this.modalRef.splice(idx, 1);
            }
            this.activeInstances$.next(this.activeInstances);
            componentRef.destroy();
        };

        this.modalRef.push(componentRef);

        componentRef$.next(componentRef);
        componentRef$.complete();

        return <Observable<ComponentRef<T>>>componentRef$.asObservable();
    }
}

// this is the modal-placeholder, it will container the created modals
@Component({
    selector: 'my-modal-placeholder',
    template: `<div #modalplaceholder></div>`
})
export class ModalPlaceholderComponent implements OnInit {
    @ViewChild('modalplaceholder', {static: true, read: ViewContainerRef}) viewContainerRef: ViewContainerRef;

    constructor(
        private modalService: ModalService,
        private resolver: ComponentFactoryResolver) {

    }
    ngOnInit() {
        this.modalService.registerViewContainerRef(this.viewContainerRef);
        this.modalService.RegisterResolver(this.resolver);
    }
}

export class ModalContainer {
    destroy: Function;
    componentIndex: number;
    closeModal() {
        this.destroy();
    }
}

export function Modal() {
    return function (target) {
        Object.assign(target.prototype,  ModalContainer.prototype);
    };
}

// module definition
@NgModule({
    declarations: [ModalPlaceholderComponent],
    exports: [ModalPlaceholderComponent],
    providers: [ModalService]
})
export class ModalModule {
}
