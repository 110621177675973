import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[spDropdownNotClosable]'
})
export class DropdownNotClosableDirective {

    constructor(private elementRef: ElementRef) {
    }

    contains(element: HTMLElement) {
        const thisElement: HTMLElement = this.elementRef.nativeElement;
        return thisElement.contains(element);
    }
}
