<div class="am-table-toolbar" >
    <a (click)="reload()" class="refresh">
        <i  class="fa fa-refresh fa-1x" aria-hidden="true"></i>
    </a>
    <h4>Notifications</h4>
</div>
<table class="am-table am-table-divider am-table-hover am-table-fixed-header notification-list">
    <thead>
        <tr>
            <th>Emited at</th>
            <th>Name</th>
            <th>Type</th>
            <th>Error</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let notification of notifications"(click)="openDetails(notification)"
            [class.am-active]="notification === selectedNotification">
            <td>
                <p>{{notification.emited_at | date:'short'}}</p>
                <p class="am-time-ago">{{notification.emited_at | amTimeAgo:true}} ago</p>
            </td>
            <td>{{notification.name}}</td>
            <td>
                <p *ngFor="let type of notification.type">{{type}}</p>
            </td>
            <td>{{notification.body?.has_error}}</td>
        </tr>
    </tbody>
</table>
<div class="table-detail" [class.open]="detailsOpen">
    <div class="table-detail-header">
        <a (click)="closeDetails()" class="close">
            <i  class="fa fa-times fa-1x" aria-hidden="true"></i>
        </a>
        <h4>Notification details</h4>
    </div>
    <div class="table-detail-content">
        <table class="am-table am-table-stripped notification-details" *ngIf="selectedNotification">
            <tbody>
                <tr>
                    <td>Emited at</td>
                    <td>{{selectedNotification.emited_at}}</td>
                </tr>
                <tr>
                    <td>Created at</td>
                    <td>{{selectedNotification.created_at}}</td>
                </tr>
                <tr>
                    <td>Updated at</td>
                    <td>{{selectedNotification.updated_at}}</td>
                </tr>
                <tr>
                    <td>Name</td>
                    <td>{{selectedNotification.name}}</td>
                </tr>
                <tr>
                    <td>Type</td>
                    <td>{{selectedNotification.type}}</td>
                </tr>
                <tr>
                    <td>Error</td>
                    <td>{{selectedNotification.has_error}}</td>
                </tr>
                <tr>
                    <td>Body</td>
                    <td><pre>{{selectedNotification.body | json}}</pre></td>
                </tr>
            </tbody>
        </table>
    </div>
<div>

