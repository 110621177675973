<div class="am-modal">
    <div class="am-modal-dialog" [@modal-dialog]>
        <form>
            <div class="am-modal-body">
                <h2 class="am-modal-title">Edit client info</h2>
                <div class="am-margin">
                    <label class="am-form-label" for="email">Name</label>
                    <div class="am-form-controls">
                        <input 
                            id="clientName"
                            name="clientName"
                            [(ngModel)]="client.name"
                            placeholder="client name">
                    </div>
                </div>
                <div class="am-margin">
                    <label class="am-form-label" for="email">Description</label>
                    <div class="am-form-controls">
                        <input 
                            id="clientDescription"
                            name="clientDescription"
                            [(ngModel)]="client.description"
                            placeholder="client description">
                    </div>
                </div>
            </div>
            <div class="am-modal-footer am-text-right">
                <button class="am-button am-button-default am-modal-close" type="button" (click)="onCancelInt()">Cancel</button>
                <button class="am-button am-button-primary" (click)="onSaveInt()">Save</button>
            </div>
        </form>
    </div>
</div>