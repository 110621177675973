import { NgModule, ApplicationRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ClientsComponent } from './clients/clients.component';
import { ClientDetailsComponent } from './clientDetails/clientDetails.component';
import { ClientInfoComponent } from './clientInfo/clientInfo.component';
import { ClientNotificationsComponent } from './clientNotifications/clientNotifications.component';
import { ClientRequestsComponent } from './clientRequests/clientRequests.component';
import { ClientLinksComponent } from './clientLinks/clientLinks.component';
import { ClientLogsComponent } from './clientLogs/clientLogs.component';

import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { JobsComponent } from './jobs/jobs.component';
import { StatsComponent } from './stats/stats.component';
import { UsersComponent } from './users/users.component';

import { ApiService } from './shared';
import { routing } from './app.routing';

import { MomentModule } from 'ngx-moment';

import { DropdownModule } from './shared/dropdown';
import { BytesPipe } from './shared/bytes.pipe';
import { FilterByIdAndNamePipe } from './shared/filterbyidandname.pipe';

import { ModalModule } from './shared/modal/modal.module';
import { JsonDetailModalComponent } from './shared/modal/json-detail-modal.component';
import { ClientInfoModalComponent } from './clientInfo/clientinfo.modal.component';
import { AceEditorModule } from 'ng2-ace-editor';
import 'brace/theme/clouds';
import 'brace/mode/json.js';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    routing,
    MomentModule,
    AceEditorModule,
    DropdownModule,
    ModalModule
  ],
  declarations: [
    BytesPipe,
    FilterByIdAndNamePipe,
    AppComponent,
    HomeComponent,
    ClientsComponent,
    ClientDetailsComponent,
    ClientInfoComponent,
    ClientNotificationsComponent,
    ClientRequestsComponent,
    ClientLinksComponent,
    ClientLogsComponent,
    SubscriptionsComponent,
    JobsComponent,
    StatsComponent,
    UsersComponent,
    JsonDetailModalComponent,
    ClientInfoModalComponent
  ],
  providers: [
    ApiService
  ],
  bootstrap: [AppComponent],
  entryComponents: [JsonDetailModalComponent, ClientInfoModalComponent]
})
export class AppModule {
}
