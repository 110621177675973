<div class="client-list">
    <form class="am-search">
        <i class="fa fa-search fa-fw search-icon"></i>
        <input name="filter" [(ngModel)]="filter" class="am-search-input" type="search" placeholder="Search...">
    </form>
    <div class="client-list-content">
        <ul class="am-nav am-nav-default">
            <li *ngFor="let client of clients | FilterByIdAndName: filter" 
                [class.am-active]="isActive(client.id)">
                <a [routerLink]="[client.id, 'info']">
                    <i class="fa fa-circle fa-fw" [class.connected]="client.connected" ></i>
                    <span>{{client.name ? client.name : client.id}}</span>
                </a>
            </li>
        </ul>
    </div>
</div>
<div class="client-details">
    <router-outlet></router-outlet>
</div>

