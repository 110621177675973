<div class="am-table-toolbar" >
    <a (click)="reload()" class="refresh">
        <i  class="fa fa-refresh fa-1x" aria-hidden="true"></i>
    </a>
    <h4>Users</h4>
</div>
<table class="am-table am-table-divider am-table-hover am-table-fixed-header users-list">
    <thead>
        <tr>
            <th>Updated at</th>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Api key</th>
            <th>Disabled</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let user of users"(click)="openDetails(user)"
            [class.am-active]="user === selectedUser">
            <td>
                <p>{{user.updated_at | date:'short'}}</p>
                <p class="am-time-ago">{{user.updated_at | amTimeAgo:true}} ago</p>
            </td>
            <td>{{user.name}}</td>
            <td>{{user.email}}</td>
            <td>{{user.role}}</td>
            <td>{{user.apiKey}}</td>
            <td>{{user.disabled}}</td>
        </tr>
    </tbody>
</table>
<div class="table-detail" [class.open]="detailsOpen">
    <div class="table-detail-header">
        <a (click)="closeDetails()" class="close">
            <i  class="fa fa-times fa-1x" aria-hidden="true"></i>
        </a>
        <h4>User details</h4>
    </div>
    <div class="table-detail-content">
        <table class="am-table am-table-stripped jobs-details" *ngIf="selectedUser">
            <tbody>
                <tr>
                    <td>_id</td>
                    <td>{{selectedUser._id}}</td>
                </tr>
                <tr>
                    <td>Created at</td>
                    <td>{{selectedUser.created_at}}</td>
                </tr>
                <tr>
                    <td>Update at</td>
                    <td>{{selectedUser.updated_at}}</td>
                </tr>
                <tr>
                    <td>Name</td>
                    <td>{{selectedUser.name}}</td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>{{selectedUser.email}}</td>
                </tr>
                <tr>
                    <td>Role</td>
                    <td>{{selectedUser.role}}</td>
                </tr>
                <tr>
                    <td>ApiKey</td>
                    <td>{{selectedUser.apiKey}}</td>
                </tr>
                <tr>
                    <td>Disabled</td>
                    <td>
                        <span class="boolean-value">{{selectedUser.disabled}}</span>
                        <button (click)="toggleDisabled()">{{selectedUser.disabled ? 'Enable' : 'Disable'}}</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
<div>
