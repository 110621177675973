import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../shared';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'my-client-requests',
  templateUrl: './clientRequests.component.html',
  styleUrls: ['./clientRequests.component.scss']
})
export class ClientRequestsComponent implements OnInit, OnDestroy {
    requests: Array<any>;
    id: string;
    private sub: any;
    detailsOpen = false;
    selectedRequest: any;

    constructor(
        private apiService: ApiService,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.reload();
    }

    reload() {
        this.sub = this.route.parent.params.subscribe(params => {
            this.id = params['id'];
            this.apiService.getRequests(this.id).subscribe((items:any[]) => {
                this.requests = items;
            });
        });
    }
    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    closeDetails() {
        this.detailsOpen = false;
    }

    openDetails(item: any) {
        this.selectedRequest = item;
        this.detailsOpen = true;
    }
}
