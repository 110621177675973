import { Component,  } from '@angular/core';
import { trigger, state, animate, transition, style } from "@angular/animations";
import { ModalContainer } from '../shared/modal/modal.module';

@Component({
    host: {
        '[@modal]': 'true'
    },
    selector: 'my-clientinfo-modal',
    templateUrl: "clientinfo.modal.component.html",
    styles: [
        `input {
            width: 100%
        }`
    ],
    animations: [
        trigger('modal', [
            transition('void => *', [
                style({ opacity: 0 }),
                animate(150, style({ opacity: 1 }))
            ]),
            transition('* => void',[
                animate(150,style({ opacity: 0 }))
            ])
        ]),
        trigger('modal-dialog',[
            transition('void => *',[
                style({opacity: 0, transform: 'translateY(-100px)'}),
                animate(150)
            ]),
            transition('* => void',[
                animate(150, style({opacity: 0, transform: 'translateY(-100px)'}))
            ])
        ])
      ]
})
export class ClientInfoModalComponent extends ModalContainer {
    componentIndex: number;
    client: any;
    onSave: Function;

    onCancelInt(): void {
        this.closeModal();
    }

    onSaveInt(): void {
        this.onSave(() => {
            this.closeModal();
        });
    }

}
