import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'FilterByIdAndName',
})
export class FilterByIdAndNamePipe implements PipeTransform {
    transform(items: any[], input: string) {
        if (input) {
            input = input.toLowerCase();
            return items.filter((item: any) => {
                    return item.id.toLowerCase().indexOf(input) > -1 ||
                    (item.name ? item.name.toLowerCase().indexOf(input) > -1: false);
            });
        }
        return items;
    }
}