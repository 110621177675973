import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../shared';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '../shared/modal/modal.module';
import { ClientInfoModalComponent } from './clientinfo.modal.component';

@Component({
    selector: 'my-client-info',
    templateUrl: './clientInfo.component.html',
    styleUrls: ['./clientInfo.component.scss']
})
export class ClientInfoComponent implements OnInit, OnDestroy {
    id: string;
    client: any;
    private sub: any;
    clientProperties = new Array<any>();
    tunnels: Array<any>;

    constructor(
        private apiService: ApiService,
        private route: ActivatedRoute,
        private modalService: ModalService
    ) { }

    ngOnInit() {
        this.sub = this.route.parent.params.subscribe(params => {
            this.id = params['id'];
            this.apiService.clientGet(this.id).subscribe(cl => {
                this.client = cl;
            });
            this.getTunnels();
        });
    }

    getTunnels() {
        this.apiService.getTunnels(this.id).subscribe((items:any) => {
            if (items) {
                this.tunnels = this.generateArray(items);
            }
        });
    }

    generateArray(obj) {
        return Object.keys(obj).map((key) => {
            return {id: obj[key].id, serverAddr: obj[key].serverAddr, remoteAddr: obj[key].remoteAddr};
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    closeTunnel(tunnel) {
        this.apiService.closeTunnel(this.id, tunnel.id)
        .subscribe((result) => {
            console.log(result);
            this.getTunnels();
        });
    }

    addTunnel(port) {
        let data;
        if (isNaN(port)) {
          data = { addr: port }
        } else {
          data = { port: port }
        }
        this.apiService.addTunnel(this.id, data)
        .subscribe(result => {
            console.log(result);
            this.getTunnels();
        });
    }

    requestClientVersion() {
        this.apiService.requestClientVersion(this.id)
        .subscribe(result => {
            console.log(result);
        });
    }

    requestClientCommands() {
        this.apiService.requestClientCommands(this.id)
        .subscribe(result => {
            console.log(result);
        });
    }

    requestClientUpdate() {
        this.apiService.requestClientUpdate(this.id)
        .subscribe(result => {
            console.log(result);
        });
    }

    requestClientRestart() {
        this.apiService.requestClientRestart(this.id)
        .subscribe(result => {
            console.log(result);
        });
    }

    requestClientSystemRestart() {
        this.apiService.requestClientSystemRestart(this.id)
        .subscribe(result => {
            console.log(result);
        });
    }

    edit() {
        const editClient = {
            name: this.client.name,
            description: this.client.description
        }
        const modal = this.modalService.create(ClientInfoModalComponent, {
            client: editClient,
            onSave: (done: Function) => {
                this.apiService.clientPatch(this.id, editClient)
                .subscribe((result) => {
                    this.client = result;
                    done();
                })
            }
        });
    }

    getSedonaRetailServiceProvider() {
        const cmd = "sqlcmd -S \".\\SQLEXPRESS\" -Q \"select name, uniquecode FROM [SedonaRetail].dbo.TServiceProvider\"";
        this.apiService.cmdExec(this.id,cmd)
        .subscribe((res) => {
            console.log(res);
        });
    }

    getSedonaRetailLicenceOwner() {
        const cmd = "wmic /NAMESPACE:\\\\root\\default /NODE:'localhost' path StdRegProv call GetStringValue hDefKey=\"&H80000002\" sSubKeyName=\"SOFTWARE\\SR\\SystemIndicators\\Owner_\" sValueName=\"Owner_\"";
        this.apiService.cmdExec(this.id,cmd)
        .subscribe((res) => {
            console.log(res);
        });
    }

    getScreenshot() {
        const cmd = "node_modules\\sedona-appmon-client\\scripts\\racu.exe \"node_modules\\sedona-appmon-client\\scripts\\nircmd.exe\" \"\\\"nircmd.exe\\\" savescreenshot \\\"./tmp/savescreenshot.jpg\\\"\""
        this.apiService.cmdExec(this.id, cmd)
        .subscribe((res) => {
            console.log(res);
        });
    }

    execCmd(cmd) {
        this.apiService.cmdExec(this.id, cmd)
        .subscribe((res) => {
            console.log(res);
        });
    }
}
