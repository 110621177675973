<div class="main">
    <ul class="am-tab">
        <li [routerLinkActive]="['am-active']">
            <a [routerLink]="['info']">
                <i class="fa fa-info-circle" aria-hidden="true"></i>Info
            </a>
        </li>
        <li [routerLinkActive]="['am-active']">
            <a [routerLink]="['notifications']">
                <i class="fa fa-bell" aria-hidden="true"></i> Notifications
            </a>
        </li>
        <li [routerLinkActive]="['am-active']">
            <a [routerLink]="['requests']">
                <i class="fa fa-hourglass" aria-hidden="true"></i> Requests
            </a>
        </li>
        <li [routerLinkActive]="['am-active']">
            <a [routerLink]="['links']"><i class="fa fa-external-link" aria-hidden="true"></i> Links</a>
        </li>
        <li [routerLinkActive]="['am-active']">
            <a [routerLink]="['logs']"><i class="fa fa-list" aria-hidden="true"></i> Logs</a>
        </li>
    </ul>
    <div class="content">
        <router-outlet></router-outlet>
    </div>
</div>