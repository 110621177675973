import { Component, OnInit } from '@angular/core';
import { ApiService } from '../shared';

@Component({
    selector: 'my-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
    users = [];
    detailsOpen = false;
    selectedUser: any;

    constructor(
        private apiService: ApiService,
    ) {}

    ngOnInit() {
        this.reload();
    }

    reload() {
        this.apiService.userGet()
        .subscribe((items: any[]) =>  {
            this.users = items;
        });
    }

    closeDetails() {
        this.detailsOpen = false;
    }

    openDetails(item: any) {
        this.selectedUser = item;
        this.detailsOpen = true;
    }

    toggleDisabled() {
        this.apiService.userSetDisabled(this.selectedUser._id, !this.selectedUser.disabled)
        .subscribe(item => {
            const idx = this.users.indexOf(this.selectedUser);
            if (idx > -1) {
                this.users[idx] = item;
                this.selectedUser = this.users[idx];
            }
        });
    }
}
