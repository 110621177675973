<p>
  This is the main home page.
</p>

    <!-- <div ace-editor
        [text]="text"
        [mode]="'json'"
        [theme] = "'clouds'"
        [options]="options"
        [readOnly]="false"
        [durationBeforeCallback]="1000"
        (textChanged)="onChange($event)"
        style="display:block; height: 80vh; width:100%">
    </div> -->
