import { Component, OnInit } from '@angular/core';
import { ApiService } from '../shared';
import { Router } from '@angular/router';

@Component({
  selector: 'my-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
    clients = [];
    filter: string;
    selectedClientId: string;
    constructor(
        private apiService: ApiService,
        public router: Router
    ) {
    }

    ngOnInit() {
        this.apiService.clientsGet().subscribe((cl: any[]) => {
            this.clients = cl;
        });
    }

    isActive(id: string): boolean {
        return this.router.url.startsWith('/clients/' + id);
    }
}
