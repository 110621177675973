<div class="am-table-toolbar" >
    <a (click)="reload()" class="refresh">
        <i  class="fa fa-refresh fa-1x" aria-hidden="true"></i>
    </a>
    <h4>Subscriptions</h4>
</div>
<table class="am-table am-table-divider am-table-hover am-table-fixed-header subscription-list">
    <thead>
        <tr>
            <th>Updated at</th>
            <th>Name</th>
            <th>Events</th>
            <th>Type</th>
            <th>Template</th>
            <th>Receivers</th>
            <th>DIA</th>
            <th>DLA</th>
            <th>DIS</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let subscription of subscriptions"(click)="openDetails(subscription)" 
            [class.am-active]="subscription === selectedSubscription">
            <td>
                <p>{{subscription.updated_at | date:'short'}}</p>
                <p class="am-time-ago">{{subscription.updated_at | amTimeAgo:true}} ago</p>
            </td>
            <td>{{subscription.name}}</td>
            <td>
                <p *ngFor="let event of subscription.events">{{event}}</p>
            </td>
            <td>{{subscription.type}}</td>
            <td>{{subscription.emailTemplate}}</td>
            <td>
                <p><span>to:</span><span class="email-text">{{subscription.emailReceiver.to}}</span></p>
                <p><span>cc:</span><span class="email-text">{{subscription.emailReceiver.cc}}</span></p>
                <p><span>bcc:</span><span class="email-text">{{subscription.emailReceiver.bcc}}</span></p>
            </td>
            <td>{{subscription.disableAfter}}</td>
            <td>{{subscription.deleteAfter}}</td>
            <td>{{subscription.disabled}}</td>
        </tr>
    </tbody>
</table>

<div class="table-detail" [class.open]="detailsOpen">
    <div class="table-detail-header">
        <a (click)="closeDetails()" class="close">
            <i  class="fa fa-times fa-1x" aria-hidden="true"></i>
        </a>
        <a (click)="showDetails()"><h4>Details</h4></a>
        <a (click)="showLogs(true)"><h4>Logs</h4></a>
    </div>
    <div class="table-detail-content" *ngIf="!logsOpen">
        <table class="am-table am-table-stripped subscription-details" *ngIf="selectedSubscription">
            <tbody>
                <tr>
                    <td>_id</td>
                    <td>{{selectedSubscription._id}}</td>
                </tr>
                <tr>
                    <td>Created at</td>
                    <td>{{selectedSubscription.created_at}}</td>
                </tr>
                <tr>
                    <td>Updated at</td>
                    <td>{{selectedSubscription.updated_at}}</td>
                </tr>
                <tr>
                    <td>Name</td>
                    <td>{{selectedSubscription.name}}</td>
                </tr>
                <tr>
                    <td>Description</td>
                    <td>{{selectedSubscription.description}}</td>
                </tr>
                 <tr>
                    <td>Events</td>
                    <td><p *ngFor="let event of selectedSubscription.events">{{event}}</p></td>
                </tr> 
                <tr>
                    <td>Condition</td>
                    <td>
                        <!--<pre>{{selectedSubscription.condition | json}}</pre>-->
                        <div ace-editor class="json-editor"
                            [text]="selectedSubscription.condition | json"
                            [mode]="'json'"
                            [theme] = "'clouds'"
                            [options]="jsonEditorOptions"
                            [readOnly]="false"
                            (textChanged)="subscriptionConditionChanged($event)">
                        </div>
                        <button (click)="subscriptionUpdateCondition()">Save</button>
                    </td>
                </tr>
                <tr>
                    <td>Type</td>
                    <td>{{selectedSubscription.type}}</td>
                </tr>
                 <tr>
                    <td>Email template</td>
                    <td>{{selectedSubscription.emailTemplate}}</td>
                </tr>
                <tr>
                    <td>Email receivers</td>
                    <td>
                        <div class="table" *ngIf="!emailReceiverEdit">
                            <div class="table-row">
                                <div class="table-cel">
                                    to:
                                </div>
                                <div class="table-cell email-cell">
                                    <p *ngFor="let email of selectedSubscription.emailReceiver.to">{{email}}</p>
                                </div>
                            </div>
                            <div class="table-row"> 
                                <div class="table-cel">
                                    cc:
                                </div>
                                <div class="table-cell email-cell">
                                    <p *ngFor="let email of selectedSubscription.emailReceiver.cc">{{email}}</p>
                                </div>
                            </div>
                            <div class="table-row">
                                <div class="table-cel">
                                    bcc:
                                </div>
                                <div class="table-cell email-cell">
                                    <p *ngFor="let email of selectedSubscription.emailReceiver.bcc">{{email}}</p>
                                </div>
                            </div>
                        </div>
                        <div ace-editor class="json-editor" *ngIf="emailReceiverEdit"
                            [text]="selectedSubscription.emailReceiver | json"
                            [mode]="'json'"
                            [theme] = "'clouds'"
                            [options]="jsonEditorOptions"
                            [readOnly]="false"
                            (textChanged)="subscriptionEmailReceiverChanged($event)">
                        </div>
                        <button (click)="emailReceiverEdit=!emailReceiverEdit">{{emailReceiverEdit ? 'Close' : 'Edit'}}</button>
                        <button (click)="subscriptionUpdateEmailReceiver()" *ngIf="emailReceiverEdit">Save</button>
                    </td>
                </tr>
                <tr>
                    <td>After actions</td>
                    <td>
                        <!--<pre>{{selectedSubscription.afterActions | json}}</pre>-->
                        <div ace-editor class="json-editor"
                            [text]="selectedSubscription.afterActions | json"
                            [mode]="'json'"
                            [theme] = "'clouds'"
                            [options]="jsonEditorOptions"
                            [readOnly]="false"
                            (textChanged)="subscriptionAfterActionsChanged($event)">
                        </div>
                        <button (click)="subscriptionUpdateAfterActions()">Save</button>
                    </td>
                </tr>
                <tr>
                    <td>Disable after</td>
                    <td>
                        <span class="boolean-value">{{selectedSubscription.disableAfter}}</span>
                        <button (click)="toogleDisableAfter()">{{selectedSubscription.disableAfter ? 'Enable' : 'Disable'}}</button>
                    </td>
                </tr>
                <tr>
                    <td>Delete after</td>
                    <td>
                        <span class="boolean-value">{{selectedSubscription.deleteAfter}}</span>
                        <button (click)="toggleDeleteAfter()">{{selectedSubscription.deleteAfter ? 'Enable' : 'Disable'}}</button>
                    </td>
                </tr>
                <tr>
                    <td>Disabled</td>
                    <td>
                        <span class="boolean-value">{{selectedSubscription.disabled}}</span>
                        <button (click)="toggleDisabled()">{{selectedSubscription.disabled ? 'Enable' : 'Disable'}}</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="table-detail-content" *ngIf="logsOpen">
        <div class="am-table-toolbar" >
            <h4>Subcription logs</h4>
        </div>
        <table class="am-table am-table-divider am-table-hover am-table-fixed-header log-list">
            <thead>
                <tr>
                    <th>Created at</th>
                    <th>Updated at</th>
                    <th>Type</th>
                    <th>Message</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let log of selectedSubscriptionLogs">
                    <td>
                        <p>{{log.created_at | date:'short'}}</p>
                        <p class="am-time-ago">{{log.created_at | amTimeAgo:true}} ago</p>
                    </td>
                    <td>
                        <p>{{log.updated_at | date:'short'}}</p>
                        <p class="am-time-ago">{{log.updated_at | amTimeAgo:true}} ago</p>
                    </td>
                    <td>
                        <p *ngFor="let type of log.type">{{type}}</p>
                    </td>
                    <td>{{log.message}}</td>
                </tr>
            </tbody>
        </table>
    </div>
<div>
