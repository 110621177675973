<div class="am-table-toolbar" >
    <a (click)="reload()" class="refresh">
        <i  class="fa fa-refresh fa-1x" aria-hidden="true"></i>
    </a>
    <h4>Links</h4>
</div>
<table class="am-table am-table-divider am-table-hover am-table-fixed-header link-list">
    <thead>
        <tr>
            <th>Created at</th>
            <th>Updated at</th>
            <th>Disabled</th>
            <th>Name</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let link of links"(click)="openDetails(link)"
            [class.am-active]="link === selectedLink">
            <td>
                <p>{{link.created_at | date:'short'}}</p>
                <p class="am-time-ago">{{link.created_at | amTimeAgo:true}} ago</p>
            </td>
            <td>
                <p>{{link.updated_at | date:'short'}}</p>
                <p class="am-time-ago">{{link.updated_at | amTimeAgo:true}} ago</p>
            </td>
            <td>{{link.disabled}}</td>
            <td>{{link.name}}</td>
            <td>
                <p *ngFor="let action of link.action">{{action}}</p>
            </td>
        </tr>
    </tbody>
</table>
<div class="table-detail" [class.open]="detailsOpen">
    <div class="table-detail-header">
        <a (click)="closeDetails()" class="close">
            <i  class="fa fa-times fa-1x" aria-hidden="true"></i>
        </a>
        <h4>Link details</h4>
    </div>
    <div class="table-detail-content">
        <table class="am-table am-table-stripped link-details" *ngIf="selectedLink">
            <tbody>
                <tr>
                    <td>Created at</td>
                    <td>{{selectedLink.created_at}}</td>
                </tr>
                <tr>
                    <td>Updated at</td>
                    <td>{{selectedLink.updated_at}}</td>
                </tr>
                <tr>
                    <td>Name</td>
                    <td>{{selectedLink.name}}</td>
                </tr>
                <tr>
                    <td>Description</td>
                    <td>{{selectedLink.description}}</td>
                </tr>
                <tr>
                    <td>Action</td>
                    <td>{{selectedLink.action}}</td>
                </tr>
                <tr>
                    <td>Disabled</td>
                    <td>
                        <span class="boolean-value">{{selectedLink.disabled}}</span>
                        <button (click)="toggleDisabled()">{{selectedLink.disabled ? 'Enable': 'Disable'}}</button> 
                    </td>
                </tr>
                <!-- {{server}}/client/{{client_id}}/action/{{link_id}} -->
            </tbody>
        </table>
    </div>
<div>
