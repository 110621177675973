<div class="row">
    <div class="col-12">
        <p>Collections:{{stats_db.collections}} | Objects: {{stats_db.objects | number}} | Data size : {{stats_db.dataSize | bytes}}</p>
    </div>
</div>
<div class="row">
    <div class="col-4">
        <h4 class="stats-header">Clients</h4>
        <a class="stats-details" (click)="openStatsDetail('Clients','clients')">details</a>
        <table class="am-table am-table-stripped">
            <tr>
                <td>Number of items</td>
                <td class="am-align-right">{{stats_clients.count | number}}</td>
            </tr>
            <tr>
                <td>Data size</td>
                <td class="am-align-right">{{stats_clients.size | bytes}}</td>
            </tr>
            <tr>
                <td>Indexes</td>
                <td class="am-align-right">{{stats_clients.nindexes | number}}</td>
            </tr>
            <tr>
                <td>Total index size</td>
                <td class="am-align-right">{{stats_clients.totalIndexSize | bytes}}</td>
            </tr>
        </table>
    </div>
    <div class="col-4">
        <h4 class="stats-header">Client logs</h4>
        <a class="stats-details" (click)="openStatsDetail('Client logs','clientlogs')">details</a>
        <table class="am-table am-table-stripped">
            <tr>
                <td>Number of items</td>
                <td class="am-align-right">{{stats_clientlogs.count | number}}</td>
            </tr>
            <tr>
                <td>Data size</td>
                <td class="am-align-right">{{stats_clientlogs.size | bytes}}</td>
            </tr>
            <tr>
                <td>Indexes</td>
                <td class="am-align-right">{{stats_clientlogs.nindexes | number}}</td>
                </tr>
            <tr>
                <td>Total index size</td>
                <td class="am-align-right">{{stats_clientlogs.totalIndexSize | bytes}}</td>
            </tr>
        </table>
    </div>
    <div class="col-4">
        <h4 class="stats-header">Jobs</h4>
        <a class="stats-details" (click)="openStatsDetail('Jobs','jobs')">details</a>
        <table class="am-table am-table-stripped">
            <tr>
                <td>Number of items</td>
                <td class="am-align-right">{{stats_jobs.count | number}}</td>
            </tr>
            <tr>
                <td>Data size</td>
                <td class="am-align-right">{{stats_jobs.size | bytes}}</td>
            </tr>
            <tr>
                <td>Indexes</td>
                <td class="am-align-right">{{stats_jobs.nindexes | number}}</td>
            </tr>
            <tr>
                <td>Total index size</td>
                <td class="am-align-right">{{stats_jobs.totalIndexSize | bytes}}</td>
            </tr>
        </table>
    </div>
</div> 

<div class="row">
    <div class="col-4">
        <h4 class="stats-header">Job logs</h4>
        <a class="stats-details" (click)="openStatsDetail('Job logs','joblogs')">details</a>
        <table class="am-table am-table-stripped">
            <tr>
                <td>Number of items</td>
                <td class="am-align-right">{{stats_joblogs.count | number}}</td>
            </tr>
            <tr>
                <td>Data size</td>
                <td class="am-align-right">{{stats_joblogs.size | bytes}}</td>
            </tr>
            <tr>
                <td>Indexes</td>
                <td class="am-align-right">{{stats_joblogs.nindexes | number}}</td>
            </tr>
            <tr>
                <td>Total index size</td>
                <td class="am-align-right">{{stats_joblogs.totalIndexSize | bytes}}</td>
            </tr>
        </table>
    </div>
    <div class="col-4">
        <h4 class="stats-header">Links</h4>
        <a class="stats-details" (click)="openStatsDetail('Links','links')">details</a>
        <table class="am-table am-table-stripped">
            <tr>
                <td>Number of items</td>
                <td class="am-align-right">{{stats_links.count | number}}</td>
            </tr>
            <tr>
                <td>Data size</td>
                <td class="am-align-right">{{stats_links.size | bytes}}</td>
            </tr>
            <tr>
                <td>Indexes</td>
                <td class="am-align-right">{{stats_links.nindexes | number}}</td>
            </tr>
            <tr>
                <td>Total index size</td>
                <td class="am-align-right">{{stats_links.totalIndexSize | bytes}}</td>
            </tr>
        </table>
    </div>
    <div class="col-4">
        <h4 class="stats-header">Notifications</h4>
        <a class="stats-details" (click)="openStatsDetail('Notifications','notifications')">details</a>
        <table class="am-table am-table-stripped">
            <tr>
                <td>Number of items</td>
                <td class="am-align-right">{{stats_notifications.count | number}}</td>
            </tr>
            <tr>
                <td>Data size</td>
                <td class="am-align-right">{{stats_notifications.size | bytes}}</td>
            </tr>
            <tr>
                <td>Indexes</td>
                <td class="am-align-right">{{stats_notifications.nindexes | number}}</td>
            </tr>
            <tr>
                <td>Total index size</td>
                <td class="am-align-right">{{stats_notifications.totalIndexSize | bytes}}</td>
            </tr>
        </table>
    </div>
</div> 
<div class="row">
    <div class="col-4">
        <h4 class="stats-header">Subscriptions</h4>
        <a class="stats-details" (click)="openStatsDetail('Subscriptions','subscriptions')">details</a>
        <table class="am-table am-table-stripped">
            <tr>
                <td>Number of items</td>
                <td class="am-align-right">{{stats_subscriptions.count | number}}</td>
            </tr>
            <tr>
                <td>Data size</td>
                <td class="am-align-right">{{stats_subscriptions.size | bytes}}</td>
            </tr>
            <tr>
                <td>Indexes</td>
                <td class="am-align-right">{{stats_subscriptions.nindexes | number}}</td>
            </tr>
            <tr>
                <td>Total index size</td>
                <td class="am-align-right">{{stats_subscriptions.totalIndexSize | bytes}}</td>
            </tr>
        </table>
    </div>
    <div class="col-4">
        <h4 class="stats-header">Subscription logs</h4>
        <a class="stats-details" (click)="openStatsDetail('Subscription logs','subscriptionlogs')">details</a>
        <table class="am-table am-table-stripped">
            <tr>
                <td>Number of items</td>
                <td class="am-align-right">{{stats_subscriptionlogs.count | number}}</td>
            </tr>
            <tr>
                <td>Data size</td>
                <td class="am-align-right">{{stats_subscriptionlogs.size | bytes}}</td>
            </tr>
            <tr>
                <td>Indexes</td>
                <td class="am-align-right">{{stats_subscriptionlogs.nindexes | number}}</td>
            </tr>
            <tr>
                <td>Total index size</td>
                <td class="am-align-right">{{stats_subscriptionlogs.totalIndexSize | bytes}}</td>
            </tr>
        </table>
    </div>
    <div class="col-4">
        <h4 class="stats-header">Users</h4>
        <a class="stats-details" (click)="openStatsDetail('Users','users')">details</a>
        <table class="am-table am-table-stripped">
            <tr>
                <td>Number of items</td>
                <td class="am-align-right">{{stats_users.count | number}}</td>
            </tr>
            <tr>
                <td>Data size</td>
                <td class="am-align-right">{{stats_users.size | bytes}}</td>
            </tr>
            <tr>
                <td>Indexes</td>
                <td class="am-align-right">{{stats_users.nindexes | number}}</td>
            </tr>
            <tr>
                <td>Total index size</td>
                <td class="am-align-right">{{stats_users.totalIndexSize | bytes}}</td>
            </tr>
        </table>
    </div>
</div> 
<div class="row">
    <div class="col-4">
        <h4 class="stats-header">Requests</h4>
        <a class="stats-details" (click)="openStatsDetail('Requests','requests')">details</a>
        <table class="am-table am-table-stripped">
            <tr>
                <td>Number of items</td>
                <td class="am-align-right">{{stats_requests.count | number}}</td>
            </tr>
            <tr>
                <td>Data size</td>
                <td class="am-align-right">{{stats_requests.size | bytes}}</td>
            </tr>
            <tr>
                <td>Indexes</td>
                <td class="am-align-right">{{stats_requests.nindexes | number}}</td>
            </tr>
            <tr>
                <td>Total index size</td>
                <td class="am-align-right">{{stats_requests.totalIndexSize | bytes}}</td>
            </tr>
        </table>
    </div>
    <div class="col-4">
    </div>
    <div class="col-4">
    </div>
</div> 
