import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../shared';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'my-client-links',
    templateUrl: './clientLinks.component.html',
    styleUrls: ['./clientLinks.component.scss']
})
export class ClientLinksComponent implements OnInit, OnDestroy {
    links: Array<any>;
    id: string;
    private sub: any;
    detailsOpen = false;
    selectedLink: any =  {};

    constructor(
        private apiService: ApiService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.reload();
    }

    reload() {
        this.sub = this.route.parent.params.subscribe(params => {
            this.id = params['id'];
            this.apiService.linkGet(this.id).subscribe((items:any[]) => {
                this.links = items;
            });
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    closeDetails() {
        this.detailsOpen = false;
    }

    openDetails(item: any) {
        this.selectedLink = item;
        this.detailsOpen = true;
    }

    toggleDisabled() {
        this.apiService.linkSetDisabled(this.selectedLink._id, !this.selectedLink.disabled)
        .subscribe(item => {
            const idx = this.links.indexOf(this.selectedLink);
            if (idx > -1) {
                this.links[idx] = item;
                this.selectedLink = this.links[idx];
            }
        });
    }
}
