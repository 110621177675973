import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../shared';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'my-client-logs',
    templateUrl: './clientLogs.component.html',
    styleUrls: ['./clientLogs.component.scss']
})
export class ClientLogsComponent implements OnInit, OnDestroy {
    logs: Array<any>;
    id: string;
    private sub: any;
    detailsOpen = false;
    selectedLog: any =  {};

    constructor(
        private apiService: ApiService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.reload();
    }

    reload() {
        this.sub = this.route.parent.params.subscribe(params => {
            this.id = params['id'];
            this.apiService.getLogs(this.id).subscribe((items:any[]) => {
                this.logs = items;
            });
        });
    }
    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    closeDetails() {
        this.detailsOpen = false;
    }

    openDetails(item: any) {
        this.selectedLog = item;
        this.detailsOpen = true;
    }
}
