import { Component, OnInit } from '@angular/core';
import { ApiService } from '../shared';
import { tryParseJSON } from '../shared/utils';

@Component({
    selector: 'my-subscriptions',
    templateUrl: './subscriptions.component.html',
    styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {
    subscriptions = [];
    detailsOpen = false;
    logsOpen = false;
    selectedSubscription: any;
    selectedSubscriptionLogs: Array<any>;
    emailReceiverEdit = false;
    jsonEditorOptions = {
        showLineNumbers: true,
        showPrintMargin: false
    };

    constructor(
        private apiService: ApiService,
    ) {}

    ngOnInit() {
        this.reload();
    }

    reload() {
        this.apiService.subscriptionGet()
        .subscribe((items:any[]) =>  {
            this.subscriptions = items;
        });
    }

    closeDetails() {
        this.detailsOpen = false;
    }

    openDetails(item: any) {
        this.selectedSubscription = item;
        this.selectedSubscriptionLogs = null;
        this.detailsOpen = true;
        this.logsOpen = false;
    }

    updateSelectedSubscription(item: any) {
        const idx = this.subscriptions.indexOf(this.selectedSubscription);
        if (idx > -1) {
            this.subscriptions[idx] = item;
            this.selectedSubscription = this.subscriptions[idx];
        }
    }

    toggleDisabled() {
        this.apiService.subscriptionSetDisabled(this.selectedSubscription._id, !this.selectedSubscription.disabled)
        .subscribe(item => this.updateSelectedSubscription(item));
    }

    toogleDisableAfter() {
        this.apiService.subscriptionSetDisableAfter(this.selectedSubscription._id, !this.selectedSubscription.disableAfter)
        .subscribe(item => this.updateSelectedSubscription(item));
    }

    toggleDeleteAfter() {
        this.apiService.subscriptionSetDeleteAfter(this.selectedSubscription._id, !this.selectedSubscription.deleteAfter)
        .subscribe(item => this.updateSelectedSubscription(item));
    }

    subscriptionConditionChanged(data) {
        const dataObj = tryParseJSON(data);
        if (dataObj) {
            this.selectedSubscription.condition = dataObj;
        }
    }

    subscriptionUpdateCondition() {
        this.apiService.subscriptionUpdateCondition(this.selectedSubscription._id,this.selectedSubscription.condition)
        .subscribe(item => this.updateSelectedSubscription(item));
    }

    subscriptionAfterActionsChanged(data) {
        const dataObj = tryParseJSON(data);
        if (dataObj) {
            this.selectedSubscription.afterActions = dataObj;
        }
    }

    subscriptionUpdateAfterActions() {
        this.apiService.subscriptionUpdateAfterActions(this.selectedSubscription._id, this.selectedSubscription.afterActions)
        .subscribe(item => this.updateSelectedSubscription(item));
    }

    subscriptionEmailReceiverChanged(data) {
        const dataObj = tryParseJSON(data);
        if (dataObj) {
            this.selectedSubscription.emailReceiver = dataObj;
        }
    }

    subscriptionUpdateEmailReceiver() {
        this.apiService.subscriptionUpdateEmailReceiver(this.selectedSubscription._id, this.selectedSubscription.emailReceiver)
        .subscribe(item => {
            this.updateSelectedSubscription(item);
            this.emailReceiverEdit = false;
        });
    }

    showDetails() {
        this.logsOpen = false;
    }

    showLogs(reload: boolean) {
        this.logsOpen = true;
        if (reload) {
            this.loadSubscriptionLogs();
        }
    }

    loadSubscriptionLogs() {
        this.apiService.subscriptionGetLogs(this.selectedSubscription._id)
        .subscribe((logs:any[]) => {
            this.selectedSubscriptionLogs = logs;
        });
    }
}
