import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../shared';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'my-client-notifications',
    templateUrl: './clientNotifications.component.html',
    styleUrls: ['./clientNotifications.component.scss']
})
export class ClientNotificationsComponent implements OnInit, OnDestroy {
    notifications: Array<any>;
    id: string;
    private sub: any;
    detailsOpen = false;
    selectedNotification: any =  {};

    constructor(
        private apiService: ApiService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.reload();
    }

    reload() {
        this.sub = this.route.parent.params.subscribe(params => {
            this.id = params['id'];
            this.apiService.getNotifications(this.id)
            .subscribe((items:any[]) => {
                this.notifications = items;
            });
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    closeDetails() {
        this.detailsOpen = false;
    }

    openDetails(item: any) {
        this.selectedNotification = item;
        this.detailsOpen = true;
    }
}
