<div class="am-table-toolbar" >
    <a (click)="reload()" class="refresh">
        <i  class="fa fa-refresh fa-1x" aria-hidden="true"></i>
    </a>
    <h4>Jobs</h4>
</div>
<table class="am-table am-table-divider am-table-hover am-table-fixed-header jobs-list">
    <thead>
        <tr>
            <th>Updated at</th>
            <th>Name</th>
            <th>Cron time</th>
            <th>Run on init</th>
            <th>Running</th>
            <th>Disabled</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let job of jobs"(click)="openDetails(job)"
            [class.am-active]="job === selectedJob">
            <td>
                <p>{{job.updated_at | date:'short'}}</p>
                <p class="am-time-ago">{{job.updated_at | amTimeAgo:true}} ago</p>
            </td>
            <td>{{job.name}}</td>
            <td>{{job.cronTime}}</td>
            <td>{{job.runOnInit}}</td>
            <td>{{job.cronjob?.running}}</td>
            <td>{{job.disabled}}</td>
        </tr>
    </tbody>
</table>
<div class="table-detail" [class.open]="detailsOpen">
    <div class="table-detail-header">
        <a (click)="closeDetails()" class="close">
            <i  class="fa fa-times fa-1x" aria-hidden="true"></i>
        </a>
        <a (click)="showDetails()"><h4>Job details</h4></a>
        <a (click)="showLogs(true)"><h4>Job logs</h4></a>
    </div>
    <div class="table-detail-content" *ngIf="!logsOpen">
        <table class="am-table am-table-stripped jobs-details" *ngIf="selectedJob">
            <tbody>
                <tr>
                    <td>_id</td>
                    <td>{{selectedJob._id}}</td>
                </tr>
                <tr>
                    <td>Created at</td>
                    <td>{{selectedJob.created_at}}</td>
                </tr>
                <tr>
                    <td>Update at</td>
                    <td>{{selectedJob.updated_at}}</td>
                </tr>
                <tr>
                    <td>Name</td>
                    <td>{{selectedJob.name}}</td>
                </tr>
                <tr>
                    <td>Description</td>
                    <td>{{selectedJob.description}}</td>
                </tr>
                <tr>
                    <td>Cron time</td>
                    <td>{{selectedJob.cronTime}}</td>
                </tr>
                <tr>
                    <td>Run on init</td>
                    <td>{{selectedJob.runOnInit}}</td>
                </tr>
                <tr>
                    <td>On tick</td>
                    <td>
                        <!-- <pre>{{selectedJob.onTick | json}}</pre> -->
                        <div ace-editor class="ontick-editor"
                            [text]="selectedJob.onTick | json"
                            [mode]="'json'"
                            [theme] = "'clouds'"
                            [options]="jsonEditorOptions"
                            [readOnly]="false"
                            (textChanged)="jobOnTickChanged($event)">
                        </div>
                        <button (click)="jobUpdateOnTick()">Save</button>
                    </td>
                </tr>
                <tr>
                    <td>Cron job</td>
                    <td>{{selectedJob.cronjob | json}}</td>
                </tr>
                <tr>
                    <td>Disabled</td>
                    <td>
                        <span class="boolean-value">{{selectedJob.disabled}}</span>
                        <button (click)="toggleDisabled()">{{selectedJob.disabled ? 'Enable' : 'Disable'}}</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="table-detail-content" *ngIf="logsOpen">
            <div class="am-table-toolbar" >
                <h4>Subcription logs</h4>
            </div>
            <table class="am-table am-table-divider am-table-hover am-table-fixed-header log-list">
                <thead>
                    <tr>
                        <th>Created at</th>
                        <th>Updated at</th>
                        <th>Type</th>
                        <th>Message</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let log of selectedJobLogs">
                        <td>
                            <p>{{log.created_at | date:'short'}}</p>
                            <p class="am-time-ago">{{log.created_at | amTimeAgo:true}} ago</p>
                        </td>
                        <td>
                            <p>{{log.updated_at | date:'short'}}</p>
                            <p class="am-time-ago">{{log.updated_at | amTimeAgo:true}} ago</p>
                        </td>
                        <td>
                            <p *ngFor="let type of log.type">{{type}}</p>
                        </td>
                        <td>{{log.message}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
<div>

