import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'my-home',
    templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
    text: string;
    options: any;

    constructor() {
        this.text = '{}';
        this.options = { printMargin: false };
    }

    ngOnInit() {
        console.log('Hello Home');
    }

    onChange(data) {
        console.log(data);
    }

    modalOpen() {

    }
}
