import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ClientsComponent } from './clients/clients.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { JobsComponent } from './jobs/jobs.component';
import { StatsComponent } from './stats/stats.component';
import { ClientDetailsComponent } from './clientDetails/clientDetails.component';
import { ClientInfoComponent } from './clientInfo/clientInfo.component';
import { ClientNotificationsComponent } from './clientNotifications/clientNotifications.component';
import { ClientRequestsComponent } from './clientRequests/clientRequests.component';
import { ClientLinksComponent } from './clientLinks/clientLinks.component';
import { ClientLogsComponent } from './clientLogs/clientLogs.component';
import { UsersComponent } from './users/users.component';

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'clients', component: ClientsComponent,
        children: [{ path: ':id', component: ClientDetailsComponent,
            children: [
                { path: 'info', component: ClientInfoComponent },
                { path: 'notifications', component: ClientNotificationsComponent },
                { path: 'requests', component: ClientRequestsComponent },
                { path: 'links', component: ClientLinksComponent },
                { path: 'logs', component: ClientLogsComponent },
            ]
        }]
    },
    { path: 'subscriptions', component: SubscriptionsComponent },
    { path: 'jobs', component: JobsComponent },
    { path: 'stats', component: StatsComponent },
    { path: 'users', component: UsersComponent },
];


export const routing = RouterModule.forRoot(routes);
