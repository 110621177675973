<div class="container">
    <table class="am-table"  *ngIf="client">
        <tbody>
            <tr>
                <td>ID</td>
                <td>{{client.id}}</td>
            </tr>
            <tr>
                <td>Name</td>
                <td>{{client.name}}
                        <button class="btn btn-success" (click)="edit()">Edit</button>
                </td>
            </tr>
            <tr>
                <td>Description</td>
                <td>{{client.description}}</td>
            </tr>
            <tr>
                <td>Version</td>
                <td>{{client.version}}</td>
            </tr>
            <tr>
                <td>Connected</td>
                <td>{{client.connected}}</td>
            </tr>
            <tr>
                <td>Connected at</td>
                <td>
                    <span>{{client.connected_at | date:'short'}}</span>
                    <span class="am-time-ago">({{client.connected_at | amTimeAgo:true}} ago)</span>
                </td>
            </tr>
            <tr>
                <td>Last contact at</td>
                <td>
                    <span>{{client.last_contact_at | date:'short'}}</span>
                    <span class="am-time-ago">({{client.last_contact_at | amTimeAgo:true}} ago)</span>
                </td>
            </tr>
            <tr>
                <td>Network</td>
                <td>{{client.network?.ip}}</td>
            </tr> 
            <tr>
                <td>Apps</td>
                <td>{{client.apps}}</td>
            </tr> 
            <tr>
                <td>Notifications</td>
                <td><a [routerLink]="['../notifications']">Click here</a></td>
            </tr> 
            <tr>
                <td>Requests</td>
                <td><a [routerLink]="['../requests']">Click here</a></td>
            </tr> 
            <tr>
                <td>Links</td>
                <td><a [routerLink]="['../links']">Click here</a></td>
            </tr> 
            <tr>
                <td>Logs</td>
                <td><a [routerLink]="['../logs']">Click here</a></td>
            </tr> 
            <tr>
                <td>Tunnels</td>
                <td>
                    <div *ngFor="let tunnel of tunnels">
                        <p>{{tunnel.id}}
                                <a (click)="closeTunnel(tunnel)">Close</a>
                        </p>
                        <p>{{tunnel.serverAddr}} -> {{tunnel.remoteAddr}}</p> 
                    </div>
                    <form>
                        <div class="form-group command">
                            <label for="clientPort">Client port</label>
                            <input  #clientPort type="text" class="form-control" id="clientPort" required>
                        </div>
                        <button type="submit" class="btn btn-success" (click)="addTunnel(clientPort.value)">Add tunnel</button>
                    </form>
                </td>
            </tr>
            <tr>
                <td>Requests</td>
                <td>
                    <p><a (click)="requestClientVersion()">Client version</a></p>
                    <p><a (click)="requestClientCommands()">Client commands</a></p>
                    <p><a (click)="requestClientUpdate()">Client update</a></p>
                    <p><a (click)="requestClientRestart()">Client restart</a></p>
                    <p><a (click)="requestClientSystemRestart()">Client system restart</a></p>
                </td>
            </tr>
            <tr>
                <td>Requests other</td>
                <td>
                    <p><a (click)="getSedonaRetailServiceProvider()">SR Service Provider</a></p>
                    <p><a (click)="getSedonaRetailLicenceOwner()">SR Licence Owner</a></p>
                    <p><a (click)="getScreenshot()">Screenshot</a></p>
                    <form>
                        <div class="form-group command">
                            <label for="cmdText">Command</label>
                            <input  #cmdText type="text" class="form-control" id="cmdText" required>
                        </div>
                        <button type="submit" class="btn btn-success" (click)="execCmd(cmdText.value)">Exec</button>
                    </form>
                </td>
            </tr>
        </tbody>
    </table>
</div>
