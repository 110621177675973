<div class="am-table-toolbar" >
    <a (click)="reload()" class="refresh">
        <i  class="fa fa-refresh fa-1x" aria-hidden="true"></i>
    </a>
    <h4>Requests</h4>
</div>
<table class="am-table am-table-divider am-table-hover am-table-fixed-header request-list">
    <thead>
        <tr>
            <th>Created at</th>
            <th>Updated at</th>
            <th>Comleted</th>
            <th>Comleted at</th>
            <th>Type</th>
            <th>Error</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let request of requests"(click)="openDetails(request)"
            [class.am-active]="request === selectedRequest">
            <td>
                <p>{{request.created_at | date:'short'}}</p>
                <p class="am-time-ago">{{request.created_at | amTimeAgo:true}} ago</p>
            </td>
            <td>
                <p>{{request.updated_at | date:'short'}}</p>
                <p class="am-time-ago">{{request.updated_at | amTimeAgo:true}} ago</p>
            </td>
            <td>{{request.completed}}</td>
            <td>
                <p>{{request.completed_at | date:'short'}}</p>
                <p class="am-time-ago">{{request.completed_at | amTimeAgo:true}} ago</p>
            </td>
            <td>
                <p *ngFor="let type of request.type">{{type}}</p>
            </td>
            <td>{{request.has_error}}</td>
        </tr>
    </tbody>
</table>
<div class="table-detail" [class.open]="detailsOpen">
    <div class="table-detail-header">
        <a (click)="closeDetails()" class="close">
            <i  class="fa fa-times fa-1x" aria-hidden="true"></i>
        </a>
        <h4>Request details</h4>
    </div>
    <div class="table-detail-content">
        <table class="am-table am-table-stripped request-details" *ngIf="selectedRequest">
            <tbody>
                <tr>
                    <td>Created at</td>
                    <td>{{selectedRequest.created_at | date:'short'}}</td>
                </tr>
                <tr>
                    <td>Updated at</td>
                    <td>{{selectedRequest.updated_at | date:'short'}}</td>
                </tr>
                <tr>
                    <td>Completed</td>
                    <td>{{selectedRequest.completed}}</td>
                </tr>
                <tr>
                    <td>Completed at</td>
                    <td>{{selectedRequest.completed_at | date:'short'}}</td>
                </tr>
                <tr>
                    <td>Type</td>
                    <td>{{selectedRequest.type}}</td>
                </tr>
                <tr>
                    <td>Error</td>
                    <td>{{selectedRequest.error}}</td>
                </tr>
                <tr>
                    <td>Response</td>
                    <td><pre>{{selectedRequest.response | json}}</pre></td>
                </tr>
            </tbody>
        </table>
    </div>
<div>
