import { Component, OnInit } from '@angular/core';
import { ApiService } from '../shared';
import { tryParseJSON } from '../shared/utils';

@Component({
    selector: 'my-jobs',
    templateUrl: './jobs.component.html',
    styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
    jobs = [];
    detailsOpen = false;
    selectedJob: any;
    selectedJobLogs: Array<any>;
    logsOpen = false;
    jsonEditorOptions = {
        showLineNumbers: true,
        showPrintMargin: false
    };

    constructor(
        private apiService: ApiService,
    ) {}

    ngOnInit() {
        this.reload();
    }

    reload() {
        this.apiService.jobsGet()
        .subscribe((items: any[]) =>  {
            this.jobs = items;
        });
    }

    closeDetails() {
        this.detailsOpen = false;
    }

    openDetails(item: any) {
        this.selectedJob = item;
        this.selectedJobLogs = null;
        this.detailsOpen = true;
        this.logsOpen = false;
    }

    toggleDisabled() {
        this.apiService.jobSetDisabled(this.selectedJob._id, !this.selectedJob.disabled)
        .subscribe(item => this.updateSelectedJob(item));
    }

    jobOnTickChanged(data) {
        const dataObj = tryParseJSON(data);
        if (dataObj) {
            this.selectedJob.onTick = dataObj;
        }
    }

    jobUpdateOnTick() {
        this.apiService.jobUpadteOnTick(this.selectedJob._id,this.selectedJob.onTick)
        .subscribe(item => this.updateSelectedJob(item));
    }

    updateSelectedJob(item) {
        const idx = this.jobs.indexOf(this.selectedJob);
        if (idx > -1) {
            this.jobs[idx] = item;
            this.selectedJob = this.jobs[idx];
        }
    }

    showDetails() {
        this.logsOpen = false;
    }

    showLogs(reload: boolean) {
        this.logsOpen = true;
        if (reload) {
            this.loadJobLogs();
        }
    }

    loadJobLogs() {
        this.apiService.jobGetLogs(this.selectedJob._id)
        .subscribe((logs:any[]) => {
            this.selectedJobLogs = logs;
        });
    }
}
