import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { User } from './user.class';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from "rxjs/operators";

const httpOptions = {
    contentHeaders : new HttpHeaders(),
    contentPostHeaders:  new HttpHeaders({'Content-Type': 'application/json'}),
    jsonHeader:  new HttpHeaders({'Content-Type': 'application/json'}),
};

@Injectable()
export class ApiService {


    user: User;

    constructor(
        private http: HttpClient,
        private router: Router
    ) {

        const currentUser = this.getUserFromLocalStorage();

        if (currentUser) {
            this.user = new User(JSON.parse(currentUser));
            this.setRequestsHeaders(this.user.token);
        }
    }

    private errorHandler(response:  HttpErrorResponse): any {
        if (response.status === 401) {
            this.user = null;
            this.router.navigate(['']);
        } else {
            console.log(response);
        }
        return throwError(response);
    }

    signin(email: string, password: string) {

        return this.http.post<User>('/api/signin', {email: email, password: password}, {headers: httpOptions.jsonHeader})
        .pipe(
            map((user) => {
            this.user = user;
            this.setRequestsHeaders(this.user.token);
            this.saveUserToSessionStorage();
            return true;
            })
        );
    }

    signout() {
        this.resetUserFromLocalStorage();
        this.user = null;
        this.router.navigate(['']);
    }

    resetUserFromLocalStorage() {
        if (sessionStorage) {
            try {
                sessionStorage.removeItem('currentUser');
            } catch (e) {
                console.log('sessionStorage not availiable');
            }
        }
    }

    saveUserToSessionStorage() {
        if (sessionStorage) {
            try {
                sessionStorage.setItem('currentUser', JSON.stringify(this.user));
            } catch (e) {
                console.log('sessionStorage not availiable');
            }
        }
    }

    getUserFromLocalStorage(): string {
        if (!sessionStorage) {
            return null;
        }
        try {
            return sessionStorage.getItem('currentUser');
        } catch (e) {
            console.log('sessionStorage not availiable');
            return null;
        }
    }

    setRequestsHeaders(token: string) {

        httpOptions.contentHeaders = httpOptions.contentHeaders.set('Authorization', `Bearer ${token}`);
        httpOptions.contentPostHeaders = httpOptions.contentPostHeaders.set('Authorization', `Bearer ${token}`);
    }

    clientsGet() {
        return this.http
        .get('/api/clients', {headers: httpOptions.contentHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    clientGet(client_id: string) {
        return this.http.get('/api/client/' + client_id, {headers: httpOptions.contentHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    clientPatch(client_id: string, client: any) {
        return this.http.patch(`/api//client/${client_id}`, client, {headers: httpOptions.contentPostHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    getNotifications(id: string) {
        return this.http.get('/api/client/' + id + '/notifications?limit=100', {headers: httpOptions.contentHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    getRequests(id: string) {
        return this.http.get('/api/client/' + id + '/requests?limit=100', {headers: httpOptions.contentHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    linkGet(id: string) {
        return this.http.get('/api/client/' + id + '/links?limit=100', {headers: httpOptions.contentHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    linkSetDisabled(link_id: string, disabled: boolean) {
        return this.http.patch(`/api//link/${link_id}`, {disabled: disabled}, {headers: httpOptions.contentPostHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    getTunnels(id: string) {
        return this.http.get('/api/client/' + id + '/tunnels', {headers: httpOptions.contentHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    addTunnel(id: string, data: any) {
        return this.http.post(`/api/client/${id}/tunnel/create`, data, {headers: httpOptions.contentPostHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    closeTunnel(id: string, tunnelid: string) {
        return this.http.post(`/api/client/${id}/tunnel/close`, {tunnelid: tunnelid}, {headers: httpOptions.contentPostHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    getLogs(id: string) {
        return this.http.get('/api/client/' + id + '/logs?limit=100', {headers: httpOptions.contentHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    subscriptionGet() {
        return this.http.get('/api/subscriptions', {headers: httpOptions.contentHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    subscriptionSetDisabled(subscription_id: string, disabled: boolean) {
        return this.http.patch(`/api/subscription/${subscription_id}`, {disabled: disabled}, {headers: httpOptions.contentPostHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    subscriptionSetDisableAfter(subscription_id: string, disableAfter: boolean) {
        return this.http.patch(`/api/subscription/${subscription_id}`, {disableAfter: disableAfter}, {headers: httpOptions.contentPostHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    subscriptionSetDeleteAfter(subscription_id: string, deleteAfter: boolean) {
        return this.http.patch(`/api/subscription/${subscription_id}`, {deleteAfter: deleteAfter}, {headers: httpOptions.contentPostHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    subscriptionUpdateCondition(subscription_id: string, condition: any) {
        return this.http.patch(`/api/subscription/${subscription_id}`, {condition: condition}, {headers: httpOptions.contentPostHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    subscriptionUpdateAfterActions(subscription_id: string, afterActions: any) {
        return this.http.patch(`/api/subscription/${subscription_id}`, {afterActions: afterActions}, {headers: httpOptions.contentPostHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    subscriptionUpdateEmailReceiver(subscription_id: string, emailReceiver: any) {
        return this.http.patch(`/api/subscription/${subscription_id}`, {emailReceiver: emailReceiver}, {headers: httpOptions.contentPostHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    subscriptionGetLogs(subscription_id: string) {
        return this.http.get(`/api/subscription/${subscription_id}/logs?limit=100`, {headers: httpOptions.contentHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    jobsGet() {
        return this.http.get('/api/jobs', {headers: httpOptions.contentHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    jobSetDisabled(job_id: string, disabled: boolean) {
        return this.http.patch(`/api/job/${job_id}`, {disabled: disabled}, {headers: httpOptions.contentPostHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    jobUpadteOnTick(job_id: string, onTick: any) {
        return this.http.patch(`/api/job/${job_id}`, {onTick: onTick}, {headers: httpOptions.contentPostHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    jobGetLogs(job_id: string) {
        return this.http.get(`/api/job/${job_id}/logs?limit=100`,{headers: httpOptions.contentHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    requestClientVersion(client_id: string) {
        return this.http.get(`/api/client/${client_id}/version`, {headers: httpOptions.contentPostHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    requestClientCommands(client_id: string) {
        return this.http.get(`/api/client/${client_id}/commands`, {headers: httpOptions.contentPostHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    requestClientUpdate(client_id: string) {
        return this.http.get(`/api/client/${client_id}/update`, {headers: httpOptions.contentPostHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    requestClientRestart(client_id: string) {
        return this.http.get(`/api/client/${client_id}/restart`, {headers: httpOptions.contentPostHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    requestClientSystemRestart(client_id: string) {
        return this.http.get(`/api/client/${client_id}/systemrestart`, {headers: httpOptions.contentPostHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    userGet() {
        return this.http.get('/api/users', {headers: httpOptions.contentHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    userSetDisabled(user_id: string, disabled: boolean) {
        return this.http.patch(`/api/user/${user_id}`, {disabled: disabled}, {headers: httpOptions.contentPostHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    statsGetDb() {
        return this.http.get('/api/db/stats', {headers: httpOptions.contentHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    statsGetDbCol(colName: string) {
        return this.http.get('/api/db/stats/' + colName, {headers: httpOptions.contentHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }

    cmdExec(client_id: string, command:string) {
        return this.http.post(`/api/client/${client_id}/cmd_exec`,{command: command}, {headers: httpOptions.contentPostHeaders})
        .pipe(catchError((error) => this.errorHandler(error)));
    }
}
