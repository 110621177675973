<nav class="am-navbar-container am-navbar">
    <div class="am-navbar-left">
        <ul class="am-navbar-nav">
            <li>
              <a [routerLink]="['']">Home</a>
            </li>
            <li *ngIf="user">
              <a [routerLink]="['clients']">Clients</a>
            </li>
            <li *ngIf="user">
              <a [routerLink]="['subscriptions']">Subscriptions</a>
            </li>
            <li *ngIf="user">
              <a [routerLink]="['jobs']">Jobs</a>
            </li>
            <li *ngIf="user">
              <a [routerLink]="['stats']">Stats</a>
            </li>
            <li *ngIf="user && user.email === 'admin'">
              <a [routerLink]="['users']">Users</a>
            </li>
        </ul>
    </div>
    <div class="am-navbar-right">
        <ul class="am-navbar-nav">
            <li spDropdown>
                <a spDropdownOpen>User</a>
                <div class="am-dropdown" spDropdownNotClosable>
                    <div *ngIf="user">
                        <div class="am-margin">
                            <p class="am-form-label">Name:</p>
                            <div class="am-form-controls">
                                <p>{{user.name}}</p>
                            </div>
                        </div>
                        <div class="am-margin">
                            <p class="am-form-label">Email address</p>
                            <div class="am-form-controls">
                                <p>{{user.email}}</p>
                            </div>
                        </div>
                        <div class="am-margin">
                            <button style="float:right" (click)="signOut()">Sign out</button>
                        </div>
                    </div>
                    <div *ngIf="!user" (keyup.enter)="signIn(email.value,password.value)"> 
                        <div class="am-margin">
                            <label class="am-form-label" for="email">Email</label>
                            <div class="am-form-controls">
                                <input #email id="email" placeholder="email">
                            </div>
                        </div>

                        <div class="am-margin">
                            <label class="am-form-label" for="password">Password</label>
                            <div class="am-form-controls">
                                <input #password class="am-form-input" id="password" placeholder="password" type="password">
                            </div>
                        </div>
                        <div class="am-margin" style="height:27px">
                            <p [class.error]="signInError" class="am-form-error">Invalid user name or password.</p>
                        </div>
                        <div class="am-margin">
                            <button style="float:right" (click)="signIn(email.value,password.value)">Sign in</button>
                        </div>
                    </div>
              </div>
            </li>
        </ul>
    </div>
</nav>
<div class="am-content">
    <router-outlet></router-outlet>
</div>
<my-modal-placeholder></my-modal-placeholder>
