import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'my-client-details',
    templateUrl: './clientDetails.component.html',
    styleUrls: ['./clientDetails.component.scss']
})
export class ClientDetailsComponent implements OnInit {
    client: any;

    constructor() {
    }

    ngOnInit() {

    }

}
