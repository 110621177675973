import { Component,  } from '@angular/core';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { ModalContainer } from './modal.module';

@Component({
    host: {
        '[@modal]': 'true'
    },
    selector: 'my-json-detail-modal',
    template: `
    <div class="am-modal-container am-modal">
        <div class="am-modal-dialog" [@modal-dialog]>
            <div class="am-modal-header">
                <h2 class="am-modal-title">{{title}}</h2>
            </div>
            <div class="am-modal-body am-overflow-auto">
                <pre>{{item | json}}</pre>
            </div>
            <div class="am-modal-footer am-text-right">
                <button class="am-button am-button-default am-modal-close" type="button" (click)="onCancel()">Close</button>
        </div>
    </div>`,
    styles: [`
    .am-modal-body {
        height: calc(100% - 175px);
    }
    .am-modal-dialog {
        height: 100%;
    }
    `],
    animations: [
        trigger('modal', [
            transition('void => *', [
                style({ opacity: 0 }),
                animate(150, style({ opacity: 1 }))
            ]),
            transition('* => void',[
                animate(150,style({ opacity: 0 }))
            ])
        ]),
        trigger('modal-dialog',[
            transition('void => *',[
                style({opacity: 0, transform: 'translateY(-100px)'}),
                animate(150)
            ]),
            transition('* => void',[
                animate(150, style({opacity: 0, transform: 'translateY(-100px)'}))
            ])
        ])
      ]
})
export class JsonDetailModalComponent extends ModalContainer {
    componentIndex: number;
    item: any;
    title: string
    onCancel(): void {
        this.closeModal();
    }
}
